var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        "color-scheme": "light",
        minimize: _vm.minimize,
        show: _vm.show,
      },
      on: {
        "update:show": (value) =>
          _vm.$store.commit("set", ["sidebarShow", value]),
      },
    },
    [
      _c("CSidebarBrand", { staticClass: "menute-brand", attrs: { to: "/" } }, [
        _c("img", {
          staticClass: "c-sidebar-brand-full img-drop-shadow",
          attrs: { src: "/img/menute.be/logo.png" },
        }),
        _c("img", {
          staticClass: "c-sidebar-brand-minimized img-drop-shadow",
          attrs: { src: "/img/menute.be/logo-icon.png" },
        }),
      ]),
      _c("CRenderFunction", {
        attrs: { flat: "", contentToRender: _vm.sidebarItems },
      }),
      _c("CSidebarMinimizer", {
        staticClass: "c-d-md-down-none",
        nativeOn: {
          click: function ($event) {
            return _vm.$store.commit("toggle", "sidebarMinimize")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }